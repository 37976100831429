/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import { snackbarState } from './snackbar-state';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Snackbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  snackbarState.registerOnOpenCallback(() => {
    setIsOpen(true);
  });

  snackbarState.registerOnCloseCallback(() => {
    setIsOpen(false);
  });

  const { t } = useTranslation<['home', 'common']>();
  if (!isOpen) {
    return null;
  }

  const isError = snackbarState.type === 'error';

  return (
    <dialog
      className={clsx(
        'fixed flex m-0 flex-row items-start gap-2 left-1/2 -translate-x-1/2 max-w-96 top-6 px-6 py-4 rounded-md z-50 font-sans text-sm animate-[bounceTop_0.1s] hover:scale-[1.01] active:scale-[0.99] transition shadow-xl',
        {
          'bg-green-200': !isError,
          'bg-black text-white': isError,
        },
      )}
      open={isOpen}
    >
      <p>{snackbarState.icon ? `${snackbarState.icon} ` : null}</p>
      <p className="font-medium">{t(snackbarState.message as any)}</p>
    </dialog>
  );
};
