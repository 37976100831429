import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Sprite from '../../sprites/sprite';
import clsx from 'clsx';

import { InputError, getError, hasError } from './error';
import { validate } from 'email-validator';
import phone from 'phone';
import { Tooltip } from '../tooltip';
import { InquiryInput } from '@camp67/model';
import { useFetcher } from '@remix-run/react';
import { useDisableBodyScroll } from '../../hooks/use-disable-body-scroll';
import { Modal } from '../modal';
import { inquiryModal } from '../../state/inquiry-modal';
import { useSnapshot } from 'valtio';
import { useTranslation } from 'react-i18next';

export type GetInTouchProps = {
  onSubmit?: (inquiry: InquiryInput) => void;
  showSuccess?: boolean;
};

export type Inquiry = {
  name: string;
  phone: string;
  email: string;
  inquiry: string;
};

const validateSeeker = (inquiry: Inquiry): InputError[] => {
  const errors: InputError[] = [];

  if (!inquiry.name || inquiry.name.trim().length === 0) {
    errors.push({ type: 'name' });
  }

  if (!inquiry.email || !inquiry.email.includes('@') || !validate(inquiry.email)) {
    errors.push({ type: 'email' });
  }

  if (!inquiry.phone || !phone(inquiry.phone).isValid) {
    errors.push({ type: 'phone' });
  }

  if (!inquiry.inquiry) {
    errors.push({ type: 'inquiry' });
  }

  return errors;
};

const GetInTouch = ({ onClose }: { onClose: VoidFunction }) => {
  const fetcher = useFetcher();
  const [showSuccess, setShowSuccess] = useState(false);

  const { initialInquiry } = useSnapshot(inquiryModal);

  const [inquiry, setInquiry] = useState(initialInquiry ?? '');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [errors, setErrors] = useState<InputError[]>([]);

  const onReset = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setErrors([]);
    setName('');
    setEmail('');
    setPhone('');
    setInquiry('');
  }, []);

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setName(e.target.value);
  }, []);
  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setEmail(e.target.value);
  }, []);
  const onPhoneChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setPhone(e.target.value);
  }, []);
  const onInquiryChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setErrors([]);
    setInquiry(e.target.value);
  }, []);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const onSubmit = useCallback(
    (inquiry: InquiryInput) => {
      fetcher.submit(
        {
          ...inquiry,
        },
        { method: 'post', action: '/?index' },
      );
    },
    [fetcher],
  );

  const onSubmitHandler = useCallback(
    (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      const inquiryObj = { name, email, inquiry, phone };
      const errors = validateSeeker(inquiryObj);

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      setIsSubmitDisabled(true);
      onSubmit?.(inquiryObj);
    },
    [name, email, inquiry, phone, onSubmit],
  );

  useEffect(() => {
    if (fetcher.data && (fetcher.data as { success: boolean }).success === true) {
      setShowSuccess(true);
    }
  }, [fetcher.data]);

  const { t } = useTranslation(['common', 'home']);

  if (showSuccess) {
    return (
      <div className="w-full max-w-[440px] h-fit rounded-xl bg-[#A1F6A5] px-12 py-7">
        <h2 className="font-serif text-3xl font-bold">
          {t('home:get-in-touch.success.title')}
        </h2>
        <p className="mt-4">{t('home:get-in-touch.success.received')}</p>
      </div>
    );
  }

  return (
    <form
      className="w-full relative max-w-[95%] xs:max-w-[80%] md:max-w-[640px] h-fit rounded-md bg-gradient-to-r to-[#A1F6A5] from-[#C9FFCB] px-12 pt-8 pb-5"
      onSubmit={onSubmitHandler}
    >
      <button onClick={onClose} className="absolute top-4 right-4">
        <Sprite name="menu-close" width="67" height="67" className="w-4 h-4" />
      </button>
      <div>
        <div className="flex gap-3 items-center justify-center">
          <h2 className="font-serif text-2xl font-bold">
            {t('home:get-in-touch.title')}
          </h2>
          <Tooltip
            text={t('common:secure-tooltip')}
            className="font-mono text-xs relative"
          >
            <Sprite
              className="min-w-8 min-h-8 px-2 py-2 bg-[#7583FF] rounded-full"
              name="shield"
              width="20"
              height="21"
            />
          </Tooltip>
        </div>
        <p className="text-center mt-2">{t('home:get-in-touch.get-back')}</p>
      </div>
      <div className="mt-5 flex flex-col md:flex-col gap-3">
        <span>
          <input
            type="text"
            placeholder={t('home:get-in-touch.placeholder.name')}
            className={clsx(
              'w-full bg-white px-4 py-3.5 rounded-md outline-none border-2 leading-tight',
              hasError(errors, 'name') ? 'border-red-500' : 'border-transparent',
            )}
            onChange={onNameChange}
            value={name}
          />
          {getError(errors, 'name')}
        </span>
        <span>
          <input
            type="tel"
            placeholder={t('home:get-in-touch.placeholder.phone')}
            className={clsx(
              'w-full bg-white px-4 py-3.5 rounded-md outline-none border-2 leading-tight',
              hasError(errors, 'phone') ? 'border-red-500' : 'border-transparent',
            )}
            onChange={onPhoneChange}
            value={phone}
          />
          {getError(errors, 'phone')}
        </span>
        <span>
          <input
            type="email"
            placeholder={t('home:get-in-touch.placeholder.email')}
            className={clsx(
              'w-full bg-white px-4 py-3.5 rounded-md outline-none border-2 leading-tight',
              hasError(errors, 'email') ? 'border-red-500' : 'border-transparent',
            )}
            onChange={onEmailChange}
            value={email}
          />
          {getError(errors, 'email')}
        </span>
        <span>
          <textarea
            maxLength={250}
            placeholder={t('home:get-in-touch.placeholder.inquiry')}
            className={clsx(
              'w-full bg-white px-4 py-3.5 rounded-md outline-none border-2 leading-tight resize-y max-h-44 min-h-20',
              hasError(errors, 'inquiry') ? 'border-red-500' : 'border-transparent',
            )}
            onChange={onInquiryChange}
            value={inquiry}
          />
          {getError(errors, 'inquiry')}
        </span>
      </div>
      <div className="mt-4 flex flex-col sm:flex-row gap-4 w-full justify-normal sm:justify-end">
        <button
          className={clsx(
            'px-6 py-4 uppercase tracking-widest text-xs bg-[#80B182] text-white rounded-md',
            {
              'hover:bg-[#80B182]/60': !isSubmitDisabled,
              'opacity-50': isSubmitDisabled,
            },
          )}
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t('common:submit')}
        </button>
        <button
          className="px-6 py-4 uppercase tracking-widest text-xs bg-white hover:bg-gray-100 active:bg-gray-50 rounded-md"
          onClick={onReset}
        >
          {t('common:reset')}
        </button>
      </div>
    </form>
  );
};

interface GetInTouchModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const GetInTouchModal: React.FC<GetInTouchModalProps> = ({ isOpen, onClose }) => {
  useDisableBodyScroll(isOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <GetInTouch onClose={onClose} />
    </Modal>
  );
};
