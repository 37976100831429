import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from '@remix-run/react';
import './tailwind.css';
import { GetInTouchModal } from './components/get-in-touch/get-in-touch-modal';
import { useSnapshot } from 'valtio';
import { inquiryModal, onClose } from './state/inquiry-modal';
import './styles.css';
import { useChangeLanguage } from 'remix-i18next/react';
import i18nextServer from './localization/i18next.server';
import { LoaderFunctionArgs } from '@remix-run/cloudflare';
import { useTranslation } from 'react-i18next';
import { Snackbar } from './components/snackbar/snackbar';
import { FACEBOOK, INSTAGRAM, TWITTER, YOUTUBE } from './components/socials';
import Sprite from './sprites/sprite';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const locale = await i18nextServer.getLocale(request);
  return json({ locale });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();
  const data = useRouteLoaderData<typeof loader>('root');

  const locale = data?.locale ?? 'en';
  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Snackbar />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const { isOpen } = useSnapshot(inquiryModal);

  return (
    <>
      <Outlet />
      <GetInTouchModal isOpen={isOpen} onClose={onClose} />
      <Socials />
    </>
  );
}

const Socials = () => {
  return (
    <div className="flex md:hidden flex-col fixed right-0 top-1/2 -translate-y-1/2 bg-white rounded-l-lg px-2 py-3 shadow-lg z-20">
      <ul className="flex flex-col list-none gap-4 pt-1 xs:pt-0 items-center">
        <li>
          <a href={FACEBOOK} target="_blank" rel="noreferrer">
            <Sprite
              name="facebook"
              width="64"
              height="64"
              className="min-w-6 w-6 min-h-6 h-6"
            />
          </a>
        </li>
        <li>
          <a href={INSTAGRAM} target="_blank" rel="noreferrer">
            <img
              src="/instagram.png"
              alt="Instagram"
              className="min-w-6 w-6 min-h-6 h-6"
            />
          </a>
        </li>
        <li>
          <a href={TWITTER} target="_blank" rel="noreferrer">
            <Sprite
              name="twitter"
              width="64"
              height="64"
              className="min-w-6 w-6 min-h-6 h-6"
            />
          </a>
        </li>
        <li>
          <a href={YOUTUBE} target="_blank" rel="noreferrer">
            <Sprite
              name="youtube"
              width="64"
              height="45"
              className="min-w-6 w-6 min-h-6 h-6"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};
